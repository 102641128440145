import React from 'react'
import { graphql } from "gatsby"
import PostcardPosterDetail from '../components/postcards-detail';

export default PostcardPosterDetail

export const query = graphql`
  query KDMSKMKAM($imagesGlob: String!){
    allFile(sort: {fields: relativePath}, filter: {relativePath: {glob: $imagesGlob}}) {
      nodes {
        relativePath
        childImageSharp {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`