import React from 'react'
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'
import BaseGrid from '../components/base-grid';
import ImageFull from '../components/image-full';
import logoSvg from '../images/koupit.svg';
import { BUY_LINK } from '../contants'
import DescriptionBox from './description-box'

export default function PostcardPosterDetail({ data, pageContext: { id, description } }) {
  console.log('gql data::', data)
  if (!data) {
    return null;
  }
  const imagesByFileName = data.allFile.nodes.reduce((acc, n) => {
    if (!n.childImageSharp) return acc;
    return ({
      ...acc,
      [n.childImageSharp.fluid.originalName.replace('.png', '')]: n.childImageSharp.fluid,
    })
  }, {})

  return (
    <Layout fixedBottom={
      <a target="_blank" href={BUY_LINK}>
        <ImageFull src={logoSvg} />
      </a>
    }>
      <div style={{ position: 'relative' }}>
        <BaseGrid>
          {new Array(10).fill(0).map(() =>
            <Img key={id} fluid={imagesByFileName[id]} />
          )}
        </BaseGrid>
        <DescriptionBox text={description}>
        </DescriptionBox>

      </div>
    </Layout>
  )
}
